const stageSts = [
  {
    value: "1",
    text: "小学",
  },
  {
    value: "2",
    text: "初中",
  },
  {
    value: "3",
    text: "高中",
  },
];

const statusOps = [
  {
    key: 1,
    value: "待核销",
  },
  {
    key: 3,
    value: "已完成",
  },
];

export { statusOps, stageSts };
